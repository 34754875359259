import React from 'react'

const Overview = () => (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8002 3.6C9.84542 3.6 8.92974 3.97928 8.25461 4.65442C7.57948 5.32955 7.2002 6.24522 7.2002 7.2V28.8C7.2002 29.7548 7.57948 30.6705 8.25461 31.3456C8.92974 32.0207 9.84542 32.4 10.8002 32.4H25.2002C26.155 32.4 27.0706 32.0207 27.7458 31.3456C28.4209 30.6705 28.8002 29.7548 28.8002 28.8V13.3452C28.8 12.3905 28.4206 11.475 27.7454 10.8L21.6002 4.6548C20.9252 3.97962 20.0097 3.6002 19.055 3.6H10.8002ZM14.4002 21.6C14.4002 21.1226 14.2106 20.6648 13.873 20.3272C13.5354 19.9896 13.0776 19.8 12.6002 19.8C12.1228 19.8 11.665 19.9896 11.3274 20.3272C10.9898 20.6648 10.8002 21.1226 10.8002 21.6V27C10.8002 27.4774 10.9898 27.9352 11.3274 28.2728C11.665 28.6104 12.1228 28.8 12.6002 28.8C13.0776 28.8 13.5354 28.6104 13.873 28.2728C14.2106 27.9352 14.4002 27.4774 14.4002 27V21.6ZM18.0002 16.2C18.4776 16.2 18.9354 16.3896 19.273 16.7272C19.6106 17.0648 19.8002 17.5226 19.8002 18V27C19.8002 27.4774 19.6106 27.9352 19.273 28.2728C18.9354 28.6104 18.4776 28.8 18.0002 28.8C17.5228 28.8 17.065 28.6104 16.7274 28.2728C16.3898 27.9352 16.2002 27.4774 16.2002 27V18C16.2002 17.5226 16.3898 17.0648 16.7274 16.7272C17.065 16.3896 17.5228 16.2 18.0002 16.2ZM25.2002 14.4C25.2002 13.9226 25.0106 13.4648 24.673 13.1272C24.3354 12.7896 23.8776 12.6 23.4002 12.6C22.9228 12.6 22.465 12.7896 22.1274 13.1272C21.7898 13.4648 21.6002 13.9226 21.6002 14.4V27C21.6002 27.4774 21.7898 27.9352 22.1274 28.2728C22.465 28.6104 22.9228 28.8 23.4002 28.8C23.8776 28.8 24.3354 28.6104 24.673 28.2728C25.0106 27.9352 25.2002 27.4774 25.2002 27V14.4Z"
            fill="currentColor"
        />
    </svg>
)

export default Overview
