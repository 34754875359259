import React from 'react'
import { Layout, Meta } from '../components/layout'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../components/shared/landingPages/layoutComponents'
import Header from '../components/shared/homepage/header'
import PartnersBlock from '../components/shared/homepage/partnersBlock'
import ReviewsSlider from '../components/shared/homepage/reviewsSlider'
import SupportBlock from '../components/shared/homepage/supportBlock'
import FeaturesBlock from '../components/shared/homepage/featuresBlock'
import headerPng from '../images/englishlp/headerImage.png'
import Bank from '../images/englishlp/icons/bank.js'
import Vat from '../images/englishlp/icons/vat.js'
import Overview from '../images/englishlp/icons/overview.js'
import Scanning from '../images/englishlp/icons/scanning.js'
import Invoice from '../images/englishlp/icons/invoice.js'
import Personal from '../images/englishlp/icons/personal.js'
import Quote from '../images/englishlp/icons/quote.js'
import banks from '../images/englishlp/banks.png'
import step from '../images/englishlp/step.png'
import firstSlidePng from '../images/englishlp/Healthcare.png'
import secondSlidePng from '../images/englishlp/Education.png'
import thirdSlidePng from '../images/englishlp/Consultant.png'
import fourthSlidePng from '../images/englishlp/Construction.png'
import fifthSlidePng from '../images/englishlp/Designer.png'
import config from '../config'

const headerData = {
    announcer: `It's time you make a change for the better`,
    title: `The free bookkeeping tool that works for you`,
    text: `Scan your receipts, send customised invoices and calculate your taxes.`,
    headerImage: headerPng,
    altImage: `Application overview (Desktop/Mobile)`,
    textCTA: `Start now. It's free`,
}

const partnersData = {
    title: `Trusted by some of the biggest brands you know`,
}

const featuresData = {
    title: `Everything you need to run your business with confidence`,
    text: `Tellow is loaded with tools to make business bookkeeping easy`,
    textCTA: `Make a move. It's free`,
    OneSvg: Bank,
    onePoint: `Automatic Bank Link`,
    oneCardText: `You can safely link Tellow to your bank so that transactions are automatically imported.`,
    oneImage: banks,
    oneImageAlt: `Banks`,

    TwoSvg: Vat,
    twoPoint: `Filing VAT return`,
    twoCardText: `Tellow automatically keeps track of your taxes. It fills out your VAT return for you so it is ready to submit. At the click of a button the process is
    settled with the Tax and Customs Administration.`,

    ThreeSvg: Overview,
    threePoint: `Complete overview`,
    threeCardText: `Automatically generated and updated reports ensure that you always have insight into your finances and business, wherever you are.`,

    FourSvg: Scanning,
    fourPoint: `Receipt scanning`,
    fourCardText: `Tellow automatically recognizes and processes your receipts and incoming invoices. Say goodbye to manual entry.`,

    FiveSvg: Invoice,
    fivePoint: `Invoicing`,
    fiveCardText: `Create invoices in Tellow and send them directly to your customer. You will be notified as soon as a payment has been made.`,
    fiveImage: step,
    fiveImageAlt: `Service`,

    SixSvg: Personal,
    sixPoint: `Personal advice`,
    sixCardText: `Get free support via chat, e-mail and phone, anytime you need it. Our advisors are ready to help you with all your questions about online bookkeeping and
    take their time to make sure you get it.`,

    SevenSvg: Quote,
    sevenPoint: `Create quotes`,
    sevenCardText: `Create custom quotes and send them directly to the customer. When the client confirms, the quote will be immediately converted into an invoice.`,
}

const supportData = {
    title: `Front line human support. Ready to help around the clock`,
    text: `Get free and unlimited support via chat, e-mail and telephone. Our advisors are ready to help you with all your questions about online bookkeeping. Open
    weekdays from ${config.support.opensAtTime} to ${config.support.closesAtTime}.`,
}

const noticeData = {
    textCTA: `Join Tellow`,
    slides: [
        {
            cardImage: firstSlidePng,
            altImage: `Healthcare Worker Photo`,
            profession: `Healthcare`,
            person: `Sarah, 43`,
            text: `
            After testing out multiple financial platforms, it was a no-brainer to switch to Tellow. It has streamlined my invoicing, purchase order and receipt process so that everything is finally in one place!
        `,
        },
        {
            cardImage: secondSlidePng,
            altImage: `Education Worker Photo`,
            profession: `Education`,
            person: `Eve, 33`,
            text: `
            I use Tellow to manage my freelance yoga business. It made everything so much easier and saved me a lot of money on software and accounting costs. I highly recommend it!
        `,
        },
        {
            cardImage: thirdSlidePng,
            altImage: `Consultant Photo`,
            profession: `Consultancy`,
            person: `Matthias, 46`,
            text: `
            Tellow is very simple to use and automates most of my business workflows.The dashboard lets me quickly know how my business is performing. The orientation and customer support are also outstanding.
        `,
        },
        {
            cardImage: fourthSlidePng,
            altImage: `Construction Worker Photo`,
            profession: `Construction`,
            person: `Thomas, 28`,
            text: `
            My overall experience with Tellow has been awesome! It’s ridiculously easy to create professional-looking invoices and send them on the go. As a freelancer, Tellow is exactly what I need.
        `,
        },
        {
            cardImage: fifthSlidePng,
            altImage: `Industrial Designer Photo`,
            profession: `Design`,
            person: `Laura, 26`,
            text: `
            Tellow helps me with things that I'm not good at and that I have no interest in at all, namely invoicing, bookkeeping and VAT settlements. It makes the boring side of business life a little more bearable.
        `,
        },
    ],
}

const English = () => (
    <Layout>
        <Meta
            name="robots"
            indexed="noindex"
            title="Homepage | Tellow.nl"
            description="Fast and easy to use bookkeeping tool for freelancers. ✅ English speaking customer service. ✅ Official tax integration!"
            path="/en"
        />

        <Header data={headerData} />

        <RelativeContainer>
            <ComponentContainer>
                <PartnersBlock data={partnersData} />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <FeaturesBlock data={featuresData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <ReviewsSlider data={noticeData} />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <SupportBlock data={supportData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>
    </Layout>
)
export default English
