import React from 'react'

const Personal = () => (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.9999 16.2C19.4321 16.2 20.8056 15.6311 21.8183 14.6184C22.831 13.6057 23.3999 12.2322 23.3999 10.8C23.3999 9.36786 22.831 7.99434 21.8183 6.98165C20.8056 5.96895 19.4321 5.40002 17.9999 5.40002C16.5677 5.40002 15.1942 5.96895 14.1815 6.98165C13.1688 7.99434 12.5999 9.36786 12.5999 10.8C12.5999 12.2322 13.1688 13.6057 14.1815 14.6184C15.1942 15.6311 16.5677 16.2 17.9999 16.2ZM5.3999 32.4C5.3999 30.7454 5.72581 29.1069 6.35902 27.5782C6.99223 26.0495 7.92034 24.6605 9.09036 23.4905C10.2604 22.3205 11.6494 21.3924 13.1781 20.7591C14.7068 20.1259 16.3452 19.8 17.9999 19.8C19.6546 19.8 21.293 20.1259 22.8217 20.7591C24.3504 21.3924 25.7394 22.3205 26.9094 23.4905C28.0795 24.6605 29.0076 26.0495 29.6408 27.5782C30.274 29.1069 30.5999 30.7454 30.5999 32.4H5.3999Z"
            fill="currentColor"
        />
    </svg>
)

export default Personal
