/* eslint-disable react/no-array-index-key */
import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { RegisterButton } from '../../UI'

const style = {
    wrapper: tw`max-w-7xl mb-0 mx-auto`,
    grid: tw`grid grid-cols-1 lg:grid-cols-2 `,
    text: tw`text-base font-normal text-tellow-white`,
    person: tw`text-xl font-semibold text-tellow-white mb-2`,
    content: tw`flex flex-col h-auto items-start place-content-center px-6 pt-6 md:px-10 md:pt-10 bg-gradient-to-r from-tellow-purple to-indigo-500 lg:shadow-xl overflow-hidden`,
    img: tw`w-full object-cover object-center filter drop-shadow-xl mb-0 mt-auto`,
    imageContainer: tw`hidden lg:flex flex-col justify-start`,
    card: tw`rounded-2xl bg-tellow-white shadow-xl mx-auto overflow-hidden`,
    gridButton: tw`grid grid-cols-5 mt-10`,
    bigQuoteLeft: tw`text-tellow-white font-bold text-9xl -mb-10 -mt-4 opacity-20`,
    bigQuoteRight: tw`relative mr-0 ml-auto text-tellow-white font-bold text-9xl opacity-20 -mb-10`,
    registerButton: tw`text-tellow-purple mx-auto lg:mx-0`,
}

const Cards = styled.div`
    width: 95vw;
    -webkit-mask-image: -webkit-radial-gradient(white, black); // For correct displaying of rounded corners in Safari

    @media screen and (min-width: 1400px) {
        width: 1000px;
    }
`

const SliderContainer = styled.div`
    position: relative;
    overflow-x: hidden;
    width: 95vw;

    @media screen and (min-width: 1400px) {
        width: 1000px;
    }
`

const Slider = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    position: relative;
    transition: 1000ms ease-in-out;

    > div {
        width: 95vw;

        @media screen and (min-width: 1400px) {
            width: 1000px;
        }
    }
`

const SwitchButtons = styled.div`
    text-align: center;
    visibility: hidden;

    @media screen and (min-width: 768px) {
        visibility: visible;
    }

    div > button {
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
        background-color: ${({ theme }) => theme.color.tellowWhite};
        border-width: 0px;
        border-top-width: 4px;
        border-color: ${({ theme }) => theme.color.tellowGray300};
        border-style: solid;
        padding-top: 1rem;
        transition: 300ms ease-in-out;
    }

    div > button.active,
    button:hover {
        border-color: ${({ theme }) => theme.color.tellowPurple} !important;
    }
`

function ReviewsSlider({ data }) {
    const delay = 6000
    const [index, setIndex] = React.useState(0)
    const timeoutRef = React.useRef(null)

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
    }

    React.useEffect(() => {
        resetTimeout()
        timeoutRef.current = setInterval(() => setIndex((prevIndex) => (prevIndex === data.slides.length - 1 ? 0 : prevIndex + 1)), delay)
    }, [index])

    return (
        <div css={style.wrapper}>
            <Cards css={style.card}>
                <SliderContainer>
                    <Slider style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
                        {data.slides.map(({ cardImage, altImage, person, text }, slideIndex) => (
                            <div css={style.grid} key={slideIndex}>
                                <div css={style.imageContainer}>
                                    <img css={style.img} src={cardImage} alt={altImage} />
                                </div>
                                <div css={style.content}>
                                    <div css={style.bigQuoteLeft}>“</div>
                                    <div>
                                        <p css={style.person}>{person}</p>
                                        <p css={style.text}>{text}</p>
                                    </div>
                                    <RegisterButton css={style.registerButton} big primary style={{ textAlign: 'center' }} lang="en">
                                        {data.textCTA} <span className="arrow">{'->'}</span>
                                    </RegisterButton>
                                    <div css={style.bigQuoteRight}>”</div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </SliderContainer>
            </Cards>

            <SwitchButtons>
                <div css={style.gridButton}>
                    {data.slides.map(({ profession }, idx) => (
                        <button type="button" key={idx} className={`slideshowDot ${index === idx ? 'active' : ''}`} onClick={() => setIndex(idx)}>
                            {profession}
                        </button>
                    ))}
                </div>
            </SwitchButtons>
        </div>
    )
}

export default ReviewsSlider
