import React from 'react'

const Vat = () => (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.2001 7.2C6.24532 7.2 5.32964 7.57929 4.65451 8.25442C3.97938 8.92955 3.6001 9.84522 3.6001 10.8V12.6H32.4001V10.8C32.4001 9.84522 32.0208 8.92955 31.3457 8.25442C30.6705 7.57929 29.7549 7.2 28.8001 7.2H7.2001Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.4001 16.2H3.6001V25.2C3.6001 26.1548 3.97938 27.0705 4.65451 27.7456C5.32964 28.4207 6.24532 28.8 7.2001 28.8H28.8001C29.7549 28.8 30.6705 28.4207 31.3457 27.7456C32.0208 27.0705 32.4001 26.1548 32.4001 25.2V16.2ZM7.2001 23.4C7.2001 22.9226 7.38974 22.4648 7.72731 22.1272C8.06487 21.7896 8.52271 21.6 9.0001 21.6H10.8001C11.2775 21.6 11.7353 21.7896 12.0729 22.1272C12.4105 22.4648 12.6001 22.9226 12.6001 23.4C12.6001 23.8774 12.4105 24.3352 12.0729 24.6728C11.7353 25.0104 11.2775 25.2 10.8001 25.2H9.0001C8.52271 25.2 8.06487 25.0104 7.72731 24.6728C7.38974 24.3352 7.2001 23.8774 7.2001 23.4ZM16.2001 21.6C15.7227 21.6 15.2649 21.7896 14.9273 22.1272C14.5897 22.4648 14.4001 22.9226 14.4001 23.4C14.4001 23.8774 14.5897 24.3352 14.9273 24.6728C15.2649 25.0104 15.7227 25.2 16.2001 25.2H18.0001C18.4775 25.2 18.9353 25.0104 19.2729 24.6728C19.6105 24.3352 19.8001 23.8774 19.8001 23.4C19.8001 22.9226 19.6105 22.4648 19.2729 22.1272C18.9353 21.7896 18.4775 21.6 18.0001 21.6H16.2001Z"
            fill="currentColor"
        />
    </svg>
)

export default Vat
