import React from 'react'
import tw from 'twin.macro'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Block } from '../../../utils'
import { RegisterButton } from '../../UI'

const style = {
    block: tw`relative overflow-hidden`,
    container: tw`max-w-7xl py-10 mx-auto px-4 sm:px-6 md:px-8`,
    content: tw`order-2 lg:order-1 flex flex-col items-start place-content-center`,
    title: tw`text-left text-gray-800 font-black tracking-tight antialiased mt-2 mb-6 max-w-prose leading-tight text-2xl md:text-3xl lg:text-4xl`,
    wrap: tw`relative z-10 grid grid-cols-1 lg:grid-cols-2 gap-20`,
    body: tw`antialiased text-sm font-normal text-gray-500 mb-10 max-w-prose`,
    image: tw`order-1 lg:order-2 relative w-full items-center mb-6 lg:mb-0`,
    base: tw`relative w-full max-w-xl h-full object-cover object-center`,
}

const personStyle = {
    shadow: tw`shadow-purple`,
    base: tw`relative overflow-hidden rounded-2xl mb-0 object-cover object-center w-full h-40 lg:h-full`,
}
const Person = ({ name, src, shadow }) => <GatsbyImage alt={name || ''} image={src.childImageSharp.gatsbyImageData} css={[shadow && personStyle.shadow, personStyle.base]} />

const SupportHeroes = ({ imageData }) => (
    <div css={tw`relative grid grid-cols-12 w-full gap-2.5 md:rotate-deg-2`}>
        <div css={style.base} style={{ gridColumn: '3 / span 4' }}>
            <Person src={imageData.hein} shadow />
        </div>
        <div css={style.base} style={{ gridColumn: '7 / span 4' }}>
            <Person src={imageData.jelmer} />
        </div>
        <div css={style.base} style={{ gridColumn: 'span 4' }}>
            <Person src={imageData.juul} shadow />
        </div>
        <div css={style.base} style={{ gridColumn: 'span 4' }}>
            <Person src={imageData.midas} shadow />
        </div>
        <div css={style.base} style={{ gridColumn: 'span 4' }}>
            <Person src={imageData.siem} />
        </div>
    </div>
)

const SupportBlock = ({ data }) => {
    const imageData = useStaticQuery(
        graphql`
            query {
                midas: file(relativePath: { eq: "team/employees/just.png" }) {
                    ...fluidImage
                }
                hein: file(relativePath: { eq: "team/employees/xander.png" }) {
                    ...fluidImage
                }
                siem: file(relativePath: { eq: "team/employees/lois.png" }) {
                    ...fluidImage
                }
                jelmer: file(relativePath: { eq: "team/employees/remco.png" }) {
                    ...fluidImage
                }
                juul: file(relativePath: { eq: "team/employees/sylvia.png" }) {
                    ...fluidImage
                }
            }
        `
    )

    return (
        <Block bold css={style.block}>
            <div css={style.container}>
                <div css={style.wrap}>
                    <div css={style.content}>
                        <h2 css={style.title}>{data.title}</h2>
                        <p css={style.body}>{data.text}</p>
                        <RegisterButton big purple style={{ textAlign: 'center' }} lang="en">
                            Switch to Tellow <span className="arrow">{'->'}</span>
                        </RegisterButton>
                    </div>
                    <div css={style.image}>
                        <SupportHeroes imageData={imageData} />
                    </div>
                </div>
            </div>
        </Block>
    )
}

export default SupportBlock

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
        }
    }
`
