import React from 'react'
import tw from 'twin.macro'
import { RegisterButton } from '../../UI'

const style = {
    section: tw`relative overflow-hidden h-full bg-tellow-purple`,
    wrapper: tw`max-w-7xl mb-0 mx-auto px-4 sm:px-6 lg:px-8 mt-10`,
    bigTitle: tw`text-3xl lg:text-4xl font-black mb-2 text-tellow-white`,
    announcer: tw`text-sm md:text-base font-medium mb-2 text-tellow-white`,
    text: tw`text-sm md:text-base font-normal mb-6 text-tellow-white opacity-75`,
    content: tw`flex flex-col h-auto items-center pt-2 text-center md:px-10 lg:px-40`,
}

const Mockup = ({ data }) => (
    <div css={tw`relative w-full lg:w-4/5 mx-auto text-center relative z-10 mt-10`}>
        <img css={tw`relative max-w-full z-20`} src={data.headerImage} alt={data.altImage} />
        <div style={{ margin: '0 calc(80% - 80vw)', marginBottom: '-1px' }} css={tw`absolute h-4/5 bg-white left-0 right-0 bottom-0`} />
    </div>
)

const Header = React.forwardRef(({ data }, ref) => (
    <section ref={ref} css={style.section}>
        <div css={style.wrapper}>
            <div css={style.content}>
                <p css={style.announcer}>{data.announcer}</p>
                <h1 css={style.bigTitle}>{data.title}</h1>
                <p css={style.text}>{data.text}</p>
                <RegisterButton tw="text-tellow-purple" big primary style={{ textAlign: 'center' }} lang="en">
                    {data.textCTA} <span className="arrow">{'->'}</span>
                </RegisterButton>
            </div>
            <Mockup data={data} />
        </div>
    </section>
))

Header.displayName = 'Header'

export default Header
