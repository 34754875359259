import React, { forwardRef, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import belastingdienst from '../../../images/logos/partners/svg/belastingdienst.svg'
import deliveroo from '../../../images/logos/partners/svg/horizontal/deliveroo.svg'
import rabobank from '../../../images/logos/partners/svg/horizontal/rabo-mono.svg'
import ns from '../../../images/logos/partners/svg/ns.svg'
import youngones from '../../../images/logos/partners/youngones.webp'

const styles = {
    group: tw`mt-6 grid grid-cols-1 gap-0.5 sm:grid-cols-2 md:grid-cols-5 lg:mt-8`,
    wrapper: tw`max-w-7xl mb-0 mx-auto px-4 sm:px-6 lg:px-8`,
    title: tw`text-2xl lg:text-2xl font-black mb-2 text-center`,
    logo: {
        wrapper: tw`col-span-1 flex justify-center py-8 px-8 items-center`,
        base: tw`w-full max-h-10 object-scale-down mb-0 monotone!`,
    },
}

/**
 * Item variants for Framer Motion.
 */
const item = {
    visible: { opacity: 1, transition: { duration: 1.25, type: 'easeInOut' } },
    hidden: { opacity: 0 },
}

/**
 * Proxy component for Logo.
 */
const Logo = ({ props, src, alt }) => (
    <motion.div key={alt.replace(' ', '')} variants={item} css={styles.logo.wrapper} {...props}>
        <img css={styles.logo.base} src={src} alt={alt} />
    </motion.div>
)

/**
 * Container variants for Framer Motion.
 */
const container = {
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
            duration: 0.15,
        },
    },
    hidden: { opacity: 0 },
}

/**
 * ForwardRef required to use isInView ref.
 */
const Group = forwardRef(({ children, animate }, ref) => (
    <motion.div ref={ref} animate={animate} initial="hidden" variants={container} css={styles.group}>
        {children}
    </motion.div>
))
/**
 * forwardRef requires a displayName to be set.
 */
Group.displayName = 'Group'

/**
 * The actual block of code.
 */
const PartnersBlock = ({ data }) => {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    const [companies, setCompanies] = useState([])

    /**
     * Scroll into view
     * lifecycle hook.
     */
    useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [controls, inView])

    const partners = [
        { name: 'NS', source: ns },
        { name: 'Deliveroo', source: deliveroo },
        { name: 'Rabobank', source: rabobank },
        { name: 'Belastingdienst', source: belastingdienst },
        { name: 'Young Ones', source: youngones },
    ]

    /**
     * This has to happen in
     * useEffect, otherwise
     * SSR will render one
     * default view, once.
     */
    useEffect(() => {
        setCompanies(
            partners
                /**
                 * The following 'map > sort > map'
                 * flow shuffles the array.
                 *
                 * Then, we only take 6, for
                 * maximum visual pleasure. ;-)
                 * LOL
                 */
                .map((a) => [Math.random(), a])
                .sort((a, b) => a[0] - b[0])
                .map((a) => a[1])
                .slice(0, 5)
        )
    }, [])

    return (
        <section>
            <div css={styles.wrapper}>
                <h1 css={styles.title}>{data.title}</h1>
                <Group ref={ref} animate={controls}>
                    {companies.map(({ name, source }) => (
                        <Logo key={name} src={source} alt={`${name} logo`} />
                    ))}
                </Group>
            </div>
        </section>
    )
}

export default PartnersBlock
