import React from 'react'
import tw from 'twin.macro'
import { RegisterButton } from '../../UI'

const style = {
    wrapper: tw`max-w-7xl mb-0 mx-auto px-4 sm:px-6 lg:px-8`,
    grid: tw`grid grid-cols-1 md:grid-cols-3 gap-8 mt-8`,
    bigTitle: tw`text-3xl lg:text-4xl font-black mb-2`,
    text: tw`text-base font-normal mb-6`,
    content: tw`flex flex-col h-auto items-center pt-2 text-center`,
    backgroundCards: tw`rounded-md bg-tellow-white shadow-lg`,
    rowGrid: tw`row-span-2`,
    cardContainer: tw`mx-5 mt-4`,
    point: tw`text-base font-semibold mt-1`,
    divide: tw`flex flex-row items-start`,
    svg: tw`h-8 w-8 fill-none! text-tellow-purple mb-1 mr-3 rounded-full bg-tellow-purple bg-opacity-10 justify-center items-center p-1`,
    cardText: tw`text-sm font-normal`,
}

const FeaturesBlock = ({ data }) => (
    <div css={style.wrapper}>
        <div css={style.content}>
            <h1 css={style.bigTitle}>{data.title}</h1>
            <p css={style.text}>{data.text}</p>
            <RegisterButton big purple style={{ textAlign: 'center' }} lang="en">
                {data.textCTA}
            </RegisterButton>
        </div>
        <div css={style.grid}>
            <div css={[style.backgroundCards, style.rowGrid]}>
                <div css={style.cardContainer}>
                    <div css={style.divide}>
                        <div css={style.svg}>
                            <data.OneSvg />
                        </div>
                        <p css={style.point}>{data.onePoint}</p>
                    </div>
                    <p css={style.cardText}>{data.oneCardText}</p>
                    <img src={data.oneImage} alt={data.oneImageAlt} />
                </div>
            </div>
            <div css={style.backgroundCards}>
                <div css={style.cardContainer}>
                    <div css={style.divide}>
                        <div css={style.svg}>
                            <data.TwoSvg />
                        </div>
                        <p css={style.point}>{data.twoPoint}</p>
                    </div>
                    <p css={style.cardText}>{data.twoCardText}</p>
                </div>
            </div>
            <div css={style.backgroundCards}>
                <div css={style.cardContainer}>
                    <div css={style.divide}>
                        <div css={style.svg}>
                            <data.ThreeSvg />
                        </div>
                        <p css={style.point}>{data.threePoint}</p>
                    </div>
                    <p css={style.cardText}>{data.threeCardText}</p>
                </div>
            </div>
            <div css={style.backgroundCards}>
                <div css={style.cardContainer}>
                    <div css={style.divide}>
                        <div css={style.svg}>
                            <data.FourSvg />
                        </div>
                        <p css={style.point}>{data.fourPoint}</p>
                    </div>
                    <p css={style.cardText}>{data.fourCardText}</p>
                </div>
            </div>
            <div css={[style.backgroundCards, style.rowGrid]}>
                <div css={style.cardContainer}>
                    <div css={style.divide}>
                        <div css={style.svg}>
                            <data.FiveSvg />
                        </div>
                        <p css={style.point}>{data.fivePoint}</p>
                    </div>
                    <p css={style.cardText}>{data.fiveCardText}</p>
                    <img src={data.fiveImage} alt={data.fiveImageAlt} />
                </div>
            </div>
            <div css={style.backgroundCards}>
                <div css={style.cardContainer}>
                    <div css={style.divide}>
                        <div css={style.svg}>
                            <data.SixSvg />
                        </div>
                        <p css={style.point}>{data.sixPoint}</p>
                    </div>
                    <p css={style.cardText}>{data.sixCardText}</p>
                </div>
            </div>
            <div css={style.backgroundCards}>
                <div css={style.cardContainer}>
                    <div css={style.divide}>
                        <div css={style.svg}>
                            <data.SevenSvg />
                        </div>
                        <p css={style.point}>{data.sevenPoint}</p>
                    </div>
                    <p css={style.cardText}>{data.sevenCardText}</p>
                </div>
            </div>
        </div>
    </div>
)

export default FeaturesBlock
